Vue.component('wheelimage', {
  template: `
  <div class="wheelimage">
    <div class="wheelimage-inner">
      <img :src="fullurl" :data-src="fullurl_large" />
    </div>
  </div>
  `,
  data: function () {
    return {
      //ToDo: Default Image anpassen
      defaultImage: '/media/felgenbilder/default.png'
    }
  },
  props: [
    'url',
    'url_large'
  ],
  computed: {
    'fullurl': function() {
      if(this.url && this.url != 'f') {
        return this.url;
      } else {
        return this.defaultImage;
      }
    },
    'fullurl_large': function() {
      if(this.url_large && this.url_large != 'f') {
        return this.url_large;
      } else {
        return this.defaultImage;
      }
    }
  },
  mounted: function() {
    var app = this;
    if(app.url_large) {
      setTimeout(function() {
        var wheelimage = jQuery(app.$el);
        wheelimage.zoomToo({
            magnify: 1
        });
      }, 1000);

    }
  }
})